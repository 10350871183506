import { Injectable } from '@angular/core';
import { StoreAppService } from '@storefront/ng.core';
import { CartLineStatus, CartLineStoreDto, CartService, CartStoreDto, CouponService, CouponStoreDto, CouponType, CreateSalesOrderDto, DeliveryMethodService, DeliveryMethodZoneRateStoreDto, DeliveryMethodZoneRateWithAddressDto, DeliveryType, PaymentMethodDto, PaymentMethodStoreDto, SalesChannels, SalesOrderDto, SalesOrderStoreDto, StorefrontRelatedStoresDto } from '@tajer/api';
import { CartAppService } from './cart-app.service';
import { TranslateAppService } from './translate-app.service';
import { Router } from '@angular/router';
import { CustomerAppService } from './customer-app.service';
import { AddressAppService } from './address-app.service';
import { DeliveryAppService } from './delivery-app.service';
import { Observable, Subject } from 'rxjs';
import { CookieAppService } from './cookie-app.service';
import { CurrencyAppService } from './currency-app.service';

@Injectable({
  providedIn: 'root'
})
export class OrderAppService {
  //#region variants
  order = {
    salesChannel: SalesChannels.WebStore,
  } as SalesOrderDto;

  increaseQuantityLoading: boolean;
  increaseQuantityItemId: string;
  decreaseQuantityLoading: boolean;
  decreaseQuantityItemId: string;
  loadingRemove: boolean;
  removedItemId: string;
  showMethodMinimumLimitError: boolean;
  showFreeLimit: boolean;
  coupon: CouponStoreDto;
  couponType = CouponType;
  deliveryMethodZoneRate: DeliveryMethodZoneRateStoreDto;
  useLoyaltyCredits: boolean;
  loyaltyValue = 0;
  deliveryTime: string = '';
  loading: boolean;
  checkoutLoading: boolean;
  paymentMethod: PaymentMethodStoreDto;
  openAddAddressModal$: Subject<boolean>= new Subject<boolean>();
  minimumLimitErrorAlert$: Subject<boolean>= new Subject<boolean>();
  //#endregion
  
  constructor(private storeAppService: StoreAppService,
    private couponService: CouponService,
    private translateAppService: TranslateAppService,
    private router: Router,
    private customerAppService: CustomerAppService,
    private cartService: CartService,
    private deliveryMethodService: DeliveryMethodService,
    private addressAppService: AddressAppService,
    private deliveryAppService: DeliveryAppService,
    private cookieAppService: CookieAppService,
    private currencyAppService: CurrencyAppService,
    private cartAppService: CartAppService) { }

  async checkCouponCode(couponCode): Promise<string> {
    let message = '';
    let checkCouponEligiblityObservable: Observable<any>;
    if (this.isMarketplace && this.cookieAppService.getMarketTenantId() != this.cookieAppService.getTenantId()) {
      checkCouponEligiblityObservable = this.couponService.checkEligibility(couponCode, this.cartAppService.shoppingCart.subTotal, this.getRelatedStore(this.cookieAppService.getMarketTenantId())?.storeId, this.currencyAppService.requestCurrencyId);
    } else {
      checkCouponEligiblityObservable = this.couponService.checkEligibility(couponCode, this.cartAppService.shoppingCart.subTotal, null, this.currencyAppService.requestCurrencyId);
    }
    await checkCouponEligiblityObservable.toPromise().then((response) => {
      this.coupon = response;
      let discount;
      if (this.coupon.type === CouponType.Percentage) {
        discount = this.cartAppService.shoppingCart.subTotal * (this.coupon.discountValue / 100);
      } else {
        discount = this.coupon.discountValue;
      }
      this.cartAppService.shoppingCart.total = this.cartAppService.shoppingCart.subTotal - discount;
      if (this.cartAppService.shoppingCart.subTotalTax > 0) {
        this.cartAppService.shoppingCart.total += this.cartAppService.shoppingCart.subTotalTax
      }
      this.cartAppService.shoppingCart.couponId = this.coupon.id;
      this.cartAppService.shoppingCart.couponCode = this.coupon.code;

      // if (this.coupon.isFreeShippingFee) {
      //   this.order.deliveryFees = 0;
      // }
    }, (error) => {
      if (error?.error?.error) {
        message = error?.error?.error.message;
        this.coupon = null;
        this.cartAppService.shoppingCart.total = this.cartAppService.shoppingCart.subTotal;
        this.cartAppService.shoppingCart.couponId = null;
        this.cartAppService.shoppingCart.couponCode = null;
      }
    });
    return message;
  }

  public get displayMethodMinimumLimitError(): boolean {
    if (this.deliveryMethodZoneRate && this.cartAppService.shoppingCart && this.deliveryMethodZoneRate.minimumDeliveryLimit) {
      return this.cartAppService.shoppingCart.subTotal < this.deliveryMethodZoneRate.minimumDeliveryLimit;
    }
    return false;
  }

  /**
   * marketTenantId: used only in case we are in marketplace store
   * used in delete the lines
   * @returns 
   */
  checkout(marketTenantId?: string) {
    if (!this.storeAppService.storeOptions.enableQuickCheckout) {
      this.goToCheckout(marketTenantId);
    } else {
      if (this.displayMethodMinimumLimitError || this.showMethodMinimumLimitError) {
        this.minimumLimitErrorAlert$.next(true);
        return;
      }
      if (this.customerAppService.hasLoggedIn || !!this?.order?.customer?.fullName) {
        this.router.navigate([this.translateAppService.getUrl('/checkout')]);
      } else {
        if (!this.merchQuickCheckout) {
          this.router.navigate([this.translateAppService.getUrl('/login-checkout')]);
        } else {
          this.router.navigate([this.translateAppService.getUrl('/checkout')]);
        }
      }
    }
  }

  get merchQuickCheckout(){
    return this.storeAppService.storeOptions.enableQuickCheckout && (this.storeAppService.currentTheme == 'merch' || this.storeAppService.currentTheme == 'anvogue')
  }

   /**
   * marketTenantId: used only in case we are in marketplace store
   * used in delete the lines
   * @returns 
   */
  goToCheckout(marketTenantId?: string) {
    if (this.checkMinimumDeliveryLimit()) {
      return;
    }
    if (this.customerAppService.hasLoggedIn || !!this.order?.customer?.fullName) {
      // check order type (delivery type)
      if (!this.isDeliveryOrder()) {
        this.router.navigate([this.translateAppService.getUrl('/payment-methods')]);
      } else {
        this.router.navigate([this.translateAppService.getUrl('/delivery-methods')]);
      }
    } else {
      this.router.navigate([this.translateAppService.getUrl('/login-checkout')]);
    }
    this.cartAppService.shoppingCart?.cartLines.filter(cl => cl.status === CartLineStatus.NotAvailable || cl.status === CartLineStatus.NoStockAvailable)?.forEach(cartLine => {
      this.removeFromCart(cartLine, marketTenantId).then(r => { });
    });
  }

  checkIfQuickCheckoutEnabled(): boolean {
    if (this.storeAppService.storeOptions.enableQuickCheckout && this.deliveryAppService.currentDeliveryType == DeliveryType.Delivery
      && !this.order?.deliveryMethodZoneRateId
      && !this.deliveryMethodZoneRate
      && (!this.order?.address || !this.order?.addressId)) {
      this.loading = true;
      this.getQuickCheckoutInfo();
      return true;
    } else {
      return false;
    }
  }

  checkIfQuickCheckoutEnabledInCheckoutPage(): boolean {
    if (this.storeAppService.storeOptions.enableQuickCheckout) {
      if (this.deliveryAppService.currentDeliveryType == DeliveryType.Delivery && !this.addressAppService.defaultChanged) {
        this.checkoutLoading = true;
        this.order.address = null;
        this.order.addressId = null;
        this.deliveryMethodZoneRate = null;
        this.order.deliveryMethodZoneRateId = null;
        this.populateDeliveryFees();
        if(!this.merchQuickCheckout){
          this.openAddAddressModal$.next(true);
        }
        // this.modalsAppService.openAddAddressModal(true, true).finally(() => {
        //   this.checkoutLoading = false;
        // });
      }
      return true;
    } else {
      return false;
    }
  }

  closeCartMenu() {
    //this.menu?.close('cart').then(r => { });
  }

  get totalCartLinesWeights(){
    let totalWeight=0;
    this.cartAppService.shoppingCart?.cartLines?.forEach(l => {
      let itemDetail = l.item.itemDetails?.find(
        (details) => details.id === l.itemDetailId
      );
      totalWeight += l.quantity * itemDetail.weight;
    });
    return totalWeight;
  }

  getQuickCheckoutInfo() {
    this.deliveryMethodService.getQuickCheckoutInfo(
      this.storeAppService.currentOutlet?.id,
      this.currencyAppService.requestCurrencyId,
      this.totalCartLinesWeights,
    ).toPromise().then((res: DeliveryMethodZoneRateWithAddressDto) => {
      this.order.address = res?.address;
      this.order.addressId = res?.address?.id;
      this.deliveryMethodZoneRate = res?.deliveryMethodZoneRate;
      this.order.deliveryMethodZoneRateId = res?.deliveryMethodZoneRate?.id;
      if (this.customerAppService.hasLoggedIn) {
        this.addressAppService.getAddresses().then((response) => {
          if (response.items?.length > 0) {
            this.addressAppService.defaultChanged = true;
          }
        })
      }
      this.populateDeliveryFees();
      this.loading = false;
    }, async (error) => {
      this.loading = false;
      //await this.utility.showError(error);
    });
  }

  populateDeliveryFees() {
    if (this.order.deliveryType != DeliveryType.Delivery) {
        this.order.deliveryFees = null;
    } else {
        let deliveryFees = this.deliveryMethodZoneRate?.deliveryFees;

        // check if free devliery applies
        // for outlet freeDeliveryLimit
        if ((this.storeAppService.currentOutlet.freeDeliveryLimit > 0 && this.storeAppService.currentOutlet.freeDeliveryLimit <= this.cartAppService.shoppingCart?.subTotal) ||
            // or for deliveryMethodZoneRate freeDeliveryLimit
            (this.deliveryMethodZoneRate?.freeDeliveryLimit > 0 && this.deliveryMethodZoneRate?.freeDeliveryLimit <= this.cartAppService.shoppingCart?.subTotal)) {
            deliveryFees = 0;
        }
        this.order.deliveryFees = deliveryFees;
    }
}

public get totalTax(): number {
  var total: number = 0;
  this.cartAppService.shoppingCart.taxLines.forEach(taxLine => {
    total += taxLine.taxTotal
  })
  return total;
}

resetOrder() {
  this.order = {} as SalesOrderDto;
  this.coupon = null;
  this.paymentMethod = null;
  this.deliveryMethodZoneRate = null;
}
  

  /**
   * marketTenantId: used only in case we are in marketplace store
   * used in delete the lines
   * @returns 
   */
  async removeFromCart(orderLine: CartLineStoreDto, marketTenantId?:string) {
    this.loadingRemove = true;
    this.removedItemId = orderLine.item.id;
    let deleteLinePromise = this.isMarketplace ? this.cartService.deleteMarketplaceLine(orderLine.id, {
      tenantId: marketTenantId,
      token: this.storeAppService.storeConfig?.token,
      currencyId: this.currencyAppService.requestCurrencyId,
    }).toPromise() :
      this.cartService.remove(orderLine.id, {
        token: this.storeAppService.storeConfig?.token,
        currencyId: this.currencyAppService.requestCurrencyId,
      }).toPromise();

    deleteLinePromise.
      then((response) => {
        const index = this.cartAppService.shoppingCart.cartLines.findIndex(x => x.id == orderLine.id);
        if (index != -1) {
          this.cartAppService.shoppingCart.cartLines.splice(index, 1);
        }
        this.updateShoppingCart(response.cart);
        this.loadingRemove = false;
        this.removedItemId = null;
      }, (error) => {
        console.log('error');
        console.log(error);
        this.loadingRemove = false;
        this.removedItemId = null;
      });
  }

  updateShoppingCart(cart: CartStoreDto) {
    this.cartAppService.shoppingCart = cart;
  }

  /**
   * for all cart lines, avaiable ones and not available.
   */
  public get shoppingLines(): CartLineStoreDto[] {
    if (this.cartAppService?.shoppingCart && this.cartAppService?.shoppingCart?.cartLines) {
      return this.cartAppService?.shoppingCart?.cartLines;
    }
    return [];
  }

  /**
   * for all cart lines, avaiable ones and not available.
   */
  public get shoppingLinesCount(): number {
    if (this.shoppingLines) {
      return this.shoppingLines?.length;
    }
    return 0;
  }

   /**
   * for all cart lines, avaiable ones and not available.
   */
   public get isShoppingCartEmpty(): boolean {
    return this.shoppingLinesCount == 0;
  }

  public get shoppingLinesAvailableCount(): number {
    if (this.shoppingLines) {
      return this.shoppingLines.filter(x => x.status == CartLineStatus.NotUpdated || x.status == CartLineStatus.ItemPriceIncreased || x.status == CartLineStatus.ItemPriceDecreased)?.length;
    }
    return 0;
  }

  public get shoppingLinesUnavailableCount(): number {
    return this.shoppingLinesCount - this.shoppingLinesAvailableCount;
  }

  public get shippingCartTotal(): number {
    let total = 0;
    if (this.cartAppService.shoppingCart) {
      total = this.cartAppService.shoppingCart?.total;
    }
    if (this.deliveryMethodZoneRate) {
      total += this.order?.deliveryFees;
    }
    if (this.useLoyaltyCredits) {
      total -= this.loyaltyValue;
    }
    return total;
  }

  checkMinimumDeliveryLimit(): boolean {
    this.showMethodMinimumLimitError = false;
    if (this.order.deliveryType == DeliveryType.Delivery) {
      if (this.cartAppService.shoppingCart && this.storeAppService.currentOutlet.minimumDeliveryLimit) {
        this.showMethodMinimumLimitError = this.cartAppService.shoppingCart.total < this.storeAppService.currentOutlet.minimumDeliveryLimit;
        return this.showMethodMinimumLimitError;
      }
    }
    return false;
  }

  openCart() {
    if (!this.deliveryAppService.currentDeliveryType) {
      this.populateDeliveryType();
    } else {
      this.order.deliveryType = this.deliveryAppService.currentDeliveryType;
    }
    this.checkMinimumDeliveryLimit();
    if (this.hasFreeOutletDelivery()) {
      this.showFreeLimit = true;
      this.order.deliveryFees = null;
    }
  }

  populateDeliveryType() {
    if (this.storeAppService.currentOutlet.availableForDelivery) {
      this.order.deliveryType = DeliveryType.Delivery;
    } else if (this.storeAppService.currentOutlet.availableForSelfPickup) {
      this.order.deliveryType = DeliveryType.SelfPickup;
    } else if (this.storeAppService.currentOutlet.availableForDineIn) {
      this.order.deliveryType = DeliveryType.DineIn;
    }
    this.deliveryAppService.currentDeliveryType = this.order.deliveryType;
  }

  hasFreeOutletDelivery() {
    if (this.cartAppService.shoppingCart && this.storeAppService.currentOutlet.freeDeliveryLimit) {
      return this.cartAppService.shoppingCart.subTotal >= this.storeAppService.currentOutlet.freeDeliveryLimit;
    }
    return false;
  }
  

  public isDeliveryOrder() {
    return this.order.deliveryType == DeliveryType.Delivery;
  }

  isDeliveryTimeDisplayed(): boolean {
    return (
        this.order?.deliveryType == DeliveryType.Delivery && this.storeAppService.currentOutlet?.deliveryLiveOrdersPreperationTime != 0 ||
        this.order?.deliveryType == DeliveryType.SelfPickup && this.storeAppService.currentOutlet?.selfPickupLiveOrdersPreperationTime != 0 ||
        this.order?.deliveryType == DeliveryType.DineIn && this.storeAppService.currentOutlet?.dineInLiveOrdersPreperationTime != 0
    )
  }

  getDeliveryType(order: any) {
    let deliveryType = '';
    Object.entries(DeliveryType).forEach(([key, value]) => {
      if (order.deliveryType === value) {
        deliveryType = key;
      }
    });
    return deliveryType;
  }

  public get cartLinesSkus(): string[] {
    var skus = [];
    this.cartAppService.shoppingCart.cartLines.forEach(cartLine => {
      const itemDetail = cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId);
      if (itemDetail) {
        skus.push(itemDetail.sku);
      }
    })
    return skus;
  }

  public get facebookPixelContents(): CartLineStoreDto[] {
    var contentsArray = [];
    this.cartAppService.shoppingCart.cartLines.forEach(cartLine => {
      const itemDetail = cartLine?.item.itemDetails.find(x => x.id == cartLine?.itemDetailId);
      if (itemDetail) {
        contentsArray.push({ 'id': itemDetail.sku, 'quantity': cartLine.quantity });
      }
    })
    return contentsArray;
  }

  getOrderTotalTax(order: SalesOrderStoreDto): number {
    var total: number = 0;
    order?.taxLines.forEach(taxLine => {
      total += taxLine.taxTotal
    })
    return total;
  }

  get isMarketplace(){
    return this.storeAppService.storeConfig.isMarketplace;
  }

  getRelatedStore(tenantId: string): StorefrontRelatedStoresDto{
    return this.storeAppService.storeConfig.relatedStores.find(s=>s.tenantId==tenantId);
  }
  
}
