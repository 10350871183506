import { Theme } from 'src/app/shared/models/theme';

let theme = new Theme();
theme.name = 'anvogue';
theme.addStyleDependency(
  'anvogue-tailwind',
  '/assets/tailwind.min.css',
  '3.0.1',
  'tailwind'
);
theme.addStyleDependency('anvogue-magnific-popup-css', '/assets/anvogue/css/magnific-popup.css');
theme.addStyleDependency('anvogue-slick-css', '/assets/anvogue/css/slick.css');
theme.addStyleDependency('anvogue-style-css', '/assets/anvogue/css/style.css');
theme.addStyleDependency('anvogue-swiper-bundle.min-css', '/assets/anvogue/css/swiper-bundle.min.css');
theme.addStyleDependency('anvogue-output-scss-css', '/assets/anvogue/dist/output-scss.css');
theme.addStyleDependency('anvogue-output-tailwind-css', '/assets/anvogue/dist/output-tailwind1.css');

theme.addStyleDependency('anvogue-output-scss-map-css', '/assets/anvogue/dist/output-scss.css.map');
theme.addScriptDependency('anvogue-blog-js', '/assets/anvogue/js/blog.js');
theme.addScriptDependency('anvogue-jquery.min-js', '/assets/anvogue/js/jquery.min.js');
theme.addScriptDependency('anvogue-magnific-popup.min-js', '/assets/anvogue/js/magnific-popup.min.js');
theme.addScriptDependency('anvogue-product-detail-js', '/assets/anvogue/js/product-detail.js');
theme.addScriptDependency('anvogue-shop-js', '/assets/anvogue/js/shop.js');
theme.addScriptDependency('anvogue-slick.min-js', '/assets/anvogue/js/slick.min.js');
// theme.addScriptDependency('anvogue-tailwind.config-js', '/assets/anvogue/js/tailwind.config.js');
theme.addScriptDependency('anvogue-main-js', '/assets/anvogue/js/main.js');
theme.addScriptDependency('anvogue-swiper-bundle.min-js', '/assets/anvogue/js/swiper-bundle.min.js');
theme.addScriptDependency('anvogue-phosphor-icons-js', '/assets/anvogue/js/phosphor-icons.js');
theme.addFontDependency('anvogue-font', 'Inter');

export const anvogueTheme = theme;
